import { Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment, Component } from 'react';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import Login from '../../Containers/Login';
import { refreshToken } from '../../reducers/Auth';


import AppHeader from '../AppHeader';
import AppSidebar from '../AppSidebar';
const Tenants = lazy(() => import('../../Containers/Tenants'));
const Feedback = lazy(() => import('../../Containers/Feedback'));
const Requests = lazy(() => import('../../Containers/Requests'));
const Packages = lazy(() => import('../../Containers/Packages'));
const Profile = lazy(() => import('../../Containers/Profile'));
const Settings = lazy(() => import('../../Containers/Settings'));
const Guide = lazy(() => import('../../Containers/Guide'));

class AppMain extends Component {
  componentDidMount() {
    this.props.refreshToken();
  }

  render() {
    if (!this.props.Auth.loggedIn) return (
      <Login />
    );

    return (
      <Fragment>
        <AppHeader type="Admin" />
        <div className="app-main">
          <AppSidebar menuType={'admin'} />
          <div className="app-main__outer">
            {/* Tenants */}
            <Suspense fallback={
              <div className="loader-container">
                <Spinner color="primary" />
              </div>
            }>
              <Route path="/tenants" component={Tenants} />
            </Suspense>


            {/* Feedback */}
            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/feedback" component={Feedback} />
            </Suspense>



            {/* Plan Request */}
            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/requests" component={Requests} />
            </Suspense>




            {/* Packages */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/package" component={Packages} />
            </Suspense>



            {/* Profile */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/profile" component={Profile} />
            </Suspense>



            {/* Settings */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/settings" component={Settings} />
            </Suspense>



            {/* Guides */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/guide" component={Guide} />
            </Suspense>



            <Route exact path="/" render={() => (
              <Redirect to="/tenants" />
            )} />
          </div>
        </div>
      </Fragment>
    )
  };
}

const mapStateToProp = state => ({
  Auth: state.Auth,
});

const mapDispatchToProps = dispatch => {
  return {
    refreshToken: () => refreshToken(dispatch),
  }
}

export default connect(mapStateToProp, mapDispatchToProps)(AppMain);
