export const MainNav = [
    {
        icon: 'lnr-database',
        label: 'Tenants',
        to: '/tenants',
    },
    {
        icon: 'lnr-database',
        label: 'Packages',
        to: '/package',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Technical Support',
        to: '/feedback',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Plan Request',
        to: '/requests',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Change Password',
        to: '/profile',
    },
    {
        icon: 'lnr-cog',
        label: 'Settings',
        to: '/settings',
    },
    {
        icon: 'lnr-cog',
        label: 'Guide',
        to: '/guide',
    }
];
