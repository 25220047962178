import axios from 'axios';
import { BASE_URL, SUPERADMIN_TOKEN_KEY } from '../config/constants';

export function loginApi(username, password) {
  const url = `${BASE_URL}/api/v1/system_admin/login`;
  const formData = new FormData();
  formData.append('email', username);
  formData.append('password', password);
  return axios.post(url, formData);
}

export function refreshTokenApi() {
  const url = `${BASE_URL}/api/v1/system_admin/refresh`;
  const axiosInstance = axios.create({
		headers: { 'Authorization': `Bearer ${localStorage.getItem(SUPERADMIN_TOKEN_KEY)}` },
	});
  return axiosInstance.post(url);
}

export function logoutApi() {
  const url = `${BASE_URL}/api/v1/system_admin/logout`;
  const axiosInstance = axios.create({
		headers: { 'Authorization': `Bearer ${localStorage.getItem(SUPERADMIN_TOKEN_KEY)}` },
	});
  return axiosInstance.post(url);
}


export function ChnagePasswordApi(data) {
  const url = `${BASE_URL}/api/v1/system_admin/change-password`;
  const axiosInstance = axios.create({
    headers: { 'Authorization': `Bearer ${localStorage.getItem(SUPERADMIN_TOKEN_KEY)}` },
  });
  return axiosInstance.post(url, data);
}


export function UpdateMaintananceApi(data) {
  const url = `${BASE_URL}/api/v1/system_admin/SetMaintanance`;
  const axiosInstance = axios.create({
    headers: { 'Authorization': `Bearer ${localStorage.getItem(SUPERADMIN_TOKEN_KEY)}` },
  });
  return axiosInstance.post(url, data);
}


export function GetMaintananceApi() {
  const url = `${BASE_URL}/api/v1/system_admin/GetMaintananceStatus`;
  const axiosInstance = axios.create({
    headers: { 'Authorization': `Bearer ${localStorage.getItem(SUPERADMIN_TOKEN_KEY)}` },
  });
  return axiosInstance.get(url);
}
