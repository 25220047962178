import React from "react";
import ReactDOM from "react-dom/client";
import './assets/base.css';
import App from './App';
import { Provider } from 'react-redux'
import configureStore from "./store";

import {
  BrowserRouter
} from "react-router-dom";

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)